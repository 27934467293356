import Context from '../context'
import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { useEpsilonAPI, useAuthAPI } from '../hooks/api.hooks'
import LoyaltyCircleMeter from './LoyaltyCircleMeter'
import LoyaltyRewardCircle from './LoyaltyRewardCircle'
import RewardsBadge from './RewardsBadge'
import BopisTile from './BopisTile'
import { setPageViewData } from '../utils/analytics'
const { publicRuntimeConfig } = getConfig()

const RewardsBanner = () => {
  const { state, dispatch } = useContext(Context)
  const router = useRouter()
  const [userType, setUserType] = useState('')
  // State to determine whether to show the badges
  const [isVisible, setIsVisible] = useState(false)
  const [rewardDetails, setRewardDetails] = useState({})
  const [loyalty, setLoyalty] = useState(null)
  const [isBadge, setIsBadge] = useState(true)
  const [isReload, setIsReload] = useState(false)
  const [orders, setOrders] = useState([])
  const epsilonAPICall = useEpsilonAPI()
  const authAPICall = useAuthAPI()
  const currentTime = new Date()
  const firstVisitTime =
    state?.badgeFirstVisit && Object.keys(state?.badgeFirstVisit).length > 0
      ? new Date(state.badgeFirstVisit?.time)
      : null
  const expiringTime = firstVisitTime
    ? new Date(firstVisitTime.getTime() + 30 * 60 * 1000)
    : new Date(currentTime.getTime() + 30 * 60 * 1000)

  const loggedIn = !!state.user.authType
  const perksMemberTitle = `Congratulations ${state.user.firstName}!`
  const HOME_PAGE_TYPE = 'Home'

  useEffect(() => {
    setUserType(state.user.authType ? 'auth' : 'guest')
  }, [state.user.authType])

  useEffect(() => {
    if (state.user.authType) {
      epsilonAPICall('epsilon', 'profile/widget', 'GET', {}, {}, {
        'x-pop-loyaltyprofile': publicRuntimeConfig.LOYALTY_PROFILE_KEY,
        'x-posting-key': state.user.phone,
        'x-user-id': state.user.UID
      }).then((data) => {
        setLoyalty(data)
      }).catch(() => {
        console.error('Error fetching widget data')
      })
    }
  }, [state.user.authType])

  const handleRewardsDetailsChange = (data?) => {
    // NOTE: if the RewardsBanner is used on a page other
    // than index.tsx then refactor this pageView event
    if (data) {
      setRewardDetails(data)
    }
    setPageViewData(HOME_PAGE_TYPE, HOME_PAGE_TYPE, HOME_PAGE_TYPE, data || null)
  }

  useEffect(() => {
    if (loyalty) {
      let rewardDetailData = {}
      if (loyalty?.badge && loyalty?.points) {
        if (currentTime > expiringTime) {
          rewardDetailData = {
            balance: loyalty.points?.balance,
            description: loyalty.points?.description,
            title: loyalty.points?.title,
            isPoints: true
          }
          setIsBadge(false)
          handleRewardsDetailsChange(rewardDetailData)
        } else {
          rewardDetailData = {
            title: loyalty.badge?.title === 'Perks Member' ? perksMemberTitle : loyalty.badge?.title,
            description: loyalty.badge?.description,
            image_url: loyalty.badge?.image_url,
            isBadge: true
          }
          handleRewardsDetailsChange(rewardDetailData)
          if (!firstVisitTime) {
            dispatch({ type: 'BADGE_FIRST_VISIT', payload: { time: new Date() } })
          }
          const expiringUTCTime = expiringTime.toISOString().slice(0, -1)
          const currentUTCTime = currentTime.toISOString().slice(0, -1)
          const differenceInMS = calculateTimeDifference(expiringUTCTime, currentUTCTime)
          const timer = setTimeout(() => {
            rewardDetailData = {
              balance: loyalty.points?.balance,
              description: loyalty.points?.description,
              title: loyalty.points?.title,
              isPoints: true
            }
            handleRewardsDetailsChange(rewardDetailData)
            handleIsReload()
          }, differenceInMS)
          return () => clearTimeout(timer)
        }
      } else {
        if (loyalty?.reward) {
          rewardDetailData = {
            title: loyalty.reward?.title,
            amount: loyalty.reward?.amount,
            description: loyalty.reward?.description,
            isExpiringSoon: loyalty.reward?.isExpiringSoon,
            type: loyalty.reward?.type
          }
        } else if (loyalty?.points) {
          rewardDetailData = {
            balance: loyalty.points?.balance,
            description: loyalty.points?.description,
            title: loyalty.points?.title,
            isPoints: true
          }
        } else if (loyalty?.badge) {
          rewardDetailData = {
            title: loyalty.badge?.title,
            description: loyalty.badge?.description,
            image_url: loyalty.badge?.image_url,
            isBadge: true
          }
        }
        handleRewardsDetailsChange(rewardDetailData)
      }
      if (loyalty?.points?.balance > 999 && loyalty?.badge) {
        setIsVisible(currentTime > expiringTime)
      } else {
        setIsVisible(true)
      }
    } else {
      handleRewardsDetailsChange()
    }
  }, [loyalty, isReload])

  useEffect(() => {
    const getOrders = async () => {
      if (state.user.appSessionToken) {
        const data = await authAPICall('user', 'orders', 'GET', {}, {})
        if (data?.length) {
          const filtered = data.filter((order) =>
            [1, 3, 5, 6, 8, 9, 11, 12, 15, 27, 31, 26, 32].includes(order.OrderStatusID)
          )
          setOrders([...filtered])
        } else {
          setOrders([])
        }
      }
    }
    getOrders()
  }, [state.user])

  const calculateTimeDifference = (expiringUTCTime, currentUTCTime) => {
    const differenceInMilliseconds = new Date(expiringUTCTime).valueOf() - new Date(currentUTCTime).valueOf()
    return differenceInMilliseconds
  }

  const handleIsReload = () => {
    if (!isReload) {
      setIsReload(true)
    }
  }

  const rewardSubHeadingClassMap = {
    badge: 'rewards-banner__section-subheading-badge',
    points: 'rewards-banner__section-subheading-points',
    default: 'rewards-banner__section-subheading-default'
  }

  const rewardSubheadingClass = (loyalty) => {
    if (loyalty?.badge || loyalty?.reward?.type === 'discount') {
      return rewardSubHeadingClassMap.badge
    } else if (loyalty?.points?.balance > 999) {
      return rewardSubHeadingClassMap.points
    } else if (!loyalty?.reward?.isExpiringSoon) {
      return rewardSubHeadingClassMap.badge
    } else {
      return rewardSubHeadingClassMap.default
    }
  }

  const rewardContainerStyle = (loyalty) => {
    if (!loyalty?.badge) {
      return 'rewards-banner__section__details rewards-banner__section__badge'
    } else if (loyalty?.points?.balance > 999) {
      return 'rewards-banner__section__details rewards-banner__section__point-balance'
    } else {
      return 'rewards-banner__section__details'
    }
  }

  const badgeHeadingStyle = () => {
    if (currentTime < expiringTime) {
      return { color: loyalty?.badge ? '#87189D' : '#000', lineHeight: '26px' }
    }
  }

  const rewardPointsCircle = (loyalty) => {
    if (isBadge && loyalty?.badge) {
      return < RewardsBadge data={loyalty?.badge}/>
    } else if (loyalty?.points) {
      return <LoyaltyCircleMeter points={ loyalty?.points?.balance } reward={loyalty?.reward}/>
    } else if (loyalty?.reward && Object.keys(loyalty.reward).length > 0) {
      return <LoyaltyRewardCircle reward={rewardDetails}/>
    }
  }

  const onExploreButton = () => {
    router.push({
      pathname: '/rewards'
    })
  }

  const rewardsComponent = () => {
    if (loggedIn) {
      if (loyalty) {
        return (
          <>
            {rewardPointsCircle(loyalty)}
            <div className={rewardContainerStyle(loyalty)}>
              <h1 style={badgeHeadingStyle()}>{(rewardDetails as { title: string }).title}</h1>
              <p className={rewardSubheadingClass(loyalty)}>{(rewardDetails as { description: string }).description}</p>
              {isVisible && <button onClick={onExploreButton}>EXPLORE REWARDS</button>}
            </div>
          </>
        )
      } else {
        return <></>
      }
    } else {
      return (
        <>
          <h1>Register to be instantly enrolled in our rewards program, pOpshelf perks!</h1>
          <div className="rewards-banner__section__button">
            <button
              className="rewards-banner__section__button-register"
              onClick={() =>
                router.push({
                  pathname: '/registration',
                  query: { clickedElement: 'Home', redirectedURL: '' }
                })
              }
            >
              REGISTER
            </button>
            <button
              className="rewards-banner__section__button-signin"
              onClick={() =>
                router.push({
                  pathname: '/login',
                  query: { clickedElement: 'Home', redirectedURL: '' }
                })
              }
            >
              SIGN IN
            </button>
          </div>
        </>
      )
    }
  }

  return (
    userType !== 'auth'
      ? (
        <section className="rewards-banner__section">
          <div className="rewards-banner__section__sign-in">
          {rewardsComponent()}
          </div>
        </section>
        )
      : !loyalty || loyalty.status !== 200
          ? (
        <section className="rewards-banner__section">
            {orders.length > 0 && (
            <div className="rewards-banner__section__container">
              <div className="rewards-banner__section__container__inner-container">
                <div className="rewards-banner__section__container__inner-container__order">
                  <BopisTile />
                </div>
              </div>
            </div>
            )}
        </section>
            )
          : (
       <section className="rewards-banner__section">
         <div className="rewards-banner__section__container">
           {orders.length > 0
             ? (
             <div className="rewards-banner__section__container__inner-container">
               <div className="rewards-banner__section__container__inner-container__order">
                 <BopisTile />
               </div>
               <div className="rewards-banner__section__container__inner-container__reward">
                 {rewardsComponent()}
               </div>
             </div>
               )
             : (rewardsComponent())
           }
         </div>
       </section>
            )
  )
}

export default RewardsBanner
